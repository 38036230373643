import { Button, buttonVariants, Form, FormControl, FormField, FormItem, FormMessage, Input } from 'Components'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const accountFormSchema = z.object({
  email: z.string().email()
})

export const UserEmail = () => {
  const [submitted, setSubmitted] = useState(false)

  const form = useForm({
    resolver: zodResolver(accountFormSchema)
  })

  const onSubmit = async (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data)
    setSubmitted(true)
  }

  if (submitted) return <EmailConfirmationSent />

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <h3 className="text-2xl font-semibold sm:tracking-tight">Change email</h3>
          <p className="text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, vel.</p>
        </div>

        {/* Sign-in Method Section */}
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="New email" type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">Update Email</Button>
      </form>
    </Form>
  )
}

export const EmailConfirmationSent = () => {
  return (
    <div className="mx-auto flex h-full max-w-md flex-col items-center justify-center text-pretty text-center md:max-w-sm">
      <img src="/svg/mailbox.svg" alt="" className="size-48 md:size-32" />
      <h3 className="mt-10 text-2xl font-semibold sm:tracking-tight md:mt-5">Confirm your email</h3>
      <p className="mt-2 md:text-sm">
        Please confirm your email address by following the link in the email we've just sent to you. The link is valid for 2
        hours.
      </p>
      <div className="bottom-0 w-full p-4 max-md:fixed">
        <Link to={'/settings/account/profile'} className={buttonVariants({ className: 'px-6 max-md:w-full' })}>
          Got it
        </Link>
      </div>
    </div>
  )
}
