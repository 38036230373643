import { Section, SectionItem, SectionItemContent, SectionItemFooter } from 'Components'
import { SUPPORTED_TIMEZONES } from 'Constants'
import { FiCheck } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'

export const TimezoneSelector = () => {
  const currentTimezone = useSelector((state) => state.locales.timezone)

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Choose a timezone</h3>

      <Section>
        {SUPPORTED_TIMEZONES.map((timezone, i) => {
          const isActive = currentTimezone === timezone
          return (
            <SectionItem key={i} onClick={() => Actions.setTimezone(timezone)}>
              <SectionItemContent title={timezone} />
              {isActive && <SectionItemFooter icon={FiCheck} />}
            </SectionItem>
          )
        })}
      </Section>
    </>
  )
}
