import { buttonVariants } from 'Components'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { route } from 'Utils'

export const SentInvoices = () => {
  return (
    <>
      <h1>SentInvoices</h1>

      <Link to={route('invoices.create')} className={buttonVariants()}>
        <FiPlus size={5} />
        <span>New</span>
      </Link>
    </>
  )
}
