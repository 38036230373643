import { Button, CompanySelector, NotificationsMenu, UserMenu } from 'Components'
import { useIsMobile } from 'Hooks'
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

export const AppHeader = () => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const backUrl = ''

  return (
    <header className="mx-auto flex h-16 w-full max-w-(--breakpoint-xl) items-center justify-between gap-8 p-6">
      {backUrl ? (
        <Button onClick={() => navigate(-1)} icon={FiArrowLeft} variant="ghost" className="p-0">
          Torna indietro
        </Button>
      ) : (
        isMobile && <CompanySelector />
      )}
      <div className="flex grow items-center justify-end gap-4">
        <NotificationsMenu />
        {!isMobile && <UserMenu />}
      </div>
    </header>
  )
}
