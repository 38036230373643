import { cn } from 'Utils'
import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from 'Components'
import { format } from 'date-fns'
import { ro } from 'date-fns/locale'
import { FiCalendar } from 'react-icons/fi'

export function DatePicker({ field }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(' justify-start text-left font-normal', !field.value && 'text-muted-foreground')}
        >
          <FiCalendar />
          {field.value ? format(field.value, 'PPP', { locale: ro }) : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar mode="single" selected={field.value} onSelect={field.onChange} initialFocus />
      </PopoverContent>
    </Popover>
  )
}
