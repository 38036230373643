import React, { useEffect, useState } from 'react'
import { FiChrome, FiLogOut } from 'react-icons/fi'
import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader, Skeleton, Time } from 'Components'
import { useSelector } from 'react-redux'
import API from 'API'
import { Actions } from 'Store'
import { useList } from 'Hooks'
import { route } from 'Utils'

const SessionItemSkeleton = () => {
  return (
    <div className="flex items-center gap-x-4 rounded-xl px-2 py-1">
      <Skeleton className="size-12 rounded-full" />
      <div>
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="mt-1 h-4 w-[200px]" />
      </div>
    </div>
  )
}

export const AppSessions = () => {
  const sessions = useList('userSessions', 'refreshedAt', true)
  console.log('🚀 ~ AppSessions ~ sessions:', sessions)
  const [isLoading, setIsLoading] = useState(!sessions.length)

  const activeId = useSelector((state) => state.auth.account.jwtId)

  //
  sessions.splice(
    sessions.findIndex((session) => activeId === session.jwtId),
    1
  )

  useEffect(() => {
    const syncList = async () => {
      const userSessions = await API._getUserSessions()
      if (userSessions?.error) return
      Actions.populateUserSessions(userSessions)
      setIsLoading(false)
    }
    syncList()
  }, [])

  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold sm:tracking-tight">Sesiuni active</h1>

        <Section title="Sesiune curentă">
          {isLoading ? (
            <SessionItemSkeleton />
          ) : (
            <SectionItem to={route('account.sessions.session', { sessionId: activeId })}>
              <SectionItemHeader icon={FiChrome} hasBackground />
              <SectionItemContent title={'Device Name'} description={'This device'} />
              <SectionItemFooter />
            </SectionItem>
          )}
        </Section>

        <Section title="Active Sessions">
          {isLoading && [...Array(3)].map(() => <SessionItemSkeleton />)}
          {!isLoading &&
            sessions.map(({ os, jwtId, refreshedAt }, i) => (
              <SectionItem key={i} to={route('account.sessions.session', { sessionId: jwtId })}>
                <SectionItemHeader icon={FiChrome} hasBackground />
                <SectionItemContent title={os} description={<Time format="DD/MM/YYYY HH:mm" d={refreshedAt} />} />
                <SectionItemFooter />
              </SectionItem>
            ))}
        </Section>
      </div>

      {!!sessions.length && (
        <Section>
          <SectionItem onClick={() => Actions.logout(null, { allOtherSessions: true })} className="text-destructive">
            <SectionItemHeader icon={FiLogOut} />
            <SectionItemContent title="Întrerupe celelalte sesiuni" />
          </SectionItem>
        </Section>
      )}
    </>
  )
}
