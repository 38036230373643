import { Link, useLocation } from 'react-router-dom'
import { cn, route, routeIs } from 'Utils'
import { APP_MOBILE_ITEMS } from 'Constants'

export const AppFooter = () => {
  const { pathname } = useLocation()
  let items = APP_MOBILE_ITEMS.filter(({ hidden }) => !hidden)
  return (
    <footer className="bg-background fixed bottom-0 z-50 flex h-16 w-full items-center gap-4 border-t px-6 md:hidden">
      {items.map(({ icon: Icon, to }, i) => {
        return (
          <Link
            key={i}
            to={route(to)}
            className={cn('flex w-full items-center justify-center max-sm:flex-col sm:gap-2', {
              'border-primary text-primary rounded-xl': routeIs({ name: to, pathname })
            })}
          >
            <Icon className="size-6" />
          </Link>
        )
      })}
    </footer>
  )
}
