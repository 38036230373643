import React, { useEffect } from 'react'
import { ListCard, Avatar, Time, Badge, AvatarImage, AvatarFallback } from 'Components'
import API from 'API'
import { Actions } from 'Store'
import { getInitialsByName } from 'Utils'

const UserCardItem = ({ item }) => (
  <div className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors border-b last:border-b-0 dark:border-gray-700">
    <div className="flex flex-col sm:flex-row sm:items-center gap-2 py-2 px-3">
      <Avatar>
        <AvatarImage src={item.avatarUrl} alt="User avatar" />
        <AvatarFallback>{getInitialsByName(item.userName)}</AvatarFallback>
      </Avatar>

      <div className="sm:w-40 min-w-[120px]">
        <h3 className="text-sm font-medium truncate dark:text-gray-200">
          <span className="text-xs text-gray-500 dark:text-gray-400">#{item.userId}</span>
          <span className="font-normal dark:text-gray-300"> {item.userName}</span>
        </h3>
      </div>

      <div className="grow min-w-[180px] hidden sm:block">
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">{item.email}</p>
      </div>

      <div className="hidden sm:flex items-center gap-1 text-xs text-gray-500 dark:text-gray-400 min-w-[160px]">
        <Time d={item.createdAt} format="DD/MM/YYYY" />
        <span>•</span>
        <Time d={item.lastLoginAt} format="DD/MM/YYYY" />
      </div>

      <div className="flex items-center gap-1 min-w-[100px]">
        {item.hasLinkedin && (
          <Badge variant="secondary" className="bg-blue-50 text-blue-700 text-xs px-2 py-0.5">
            LinkedIn
          </Badge>
        )}
        {item.hasGoogle && (
          <Badge variant="secondary" className="bg-red-50 text-red-700 text-xs px-2 py-0.5">
            Google
          </Badge>
        )}
      </div>

      <div className="sm:hidden space-y-0.5">
        <p className="text-xs text-gray-500 dark:text-gray-400 truncate">{item.email}</p>
        <div className="flex items-center gap-1 text-xs text-gray-500 dark:text-gray-400">
          <Time d={item.createdAt} format="DD/MM/YYYY" />
          <span>•</span>
          <Time d={item.lastLoginAt} format="DD/MM/YYYY" />
        </div>
      </div>
    </div>
  </div>
)

export const AdminHumanUsersList = () => {
  useEffect(() => {
    const syncList = async () => {
      const adminHumanUsers = await API._getAdminHumanUsers()
      if (adminHumanUsers?.error) return
      Actions.populateAdminHumanUsers(adminHumanUsers)
    }
    syncList()
  }, [])

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold px-3 dark:text-gray-200">Toți utilizatorii</h2>
      <div className="bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-xs">
        <div className="hidden sm:flex items-center gap-2 py-2 px-3 bg-gray-50 dark:bg-gray-900 border-b dark:border-gray-700 text-xs font-medium text-gray-500 dark:text-gray-400">
          <div className="w-6"></div>
          <div className="w-20">ID</div>
          <div className="w-40 min-w-[120px]">Name</div>
          <div className="grow min-w-[180px]">Email</div>
          <div className="min-w-[160px]">Dates</div>
          <div className="min-w-[100px]">Social</div>
        </div>

        <ListCard id="userId" list="adminHumanUsers" ItemComponent={UserCardItem} isDesc sortKey="lastLoginAt" />
      </div>
    </div>
  )
}
