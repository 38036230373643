import { Outlet } from 'react-router-dom'
import { SidebarProvider, SidebarInset, AppSidebar, AppHeader, AppFooter, ScrollArea } from 'Components'
import { useAuthenticatedRedirects } from 'Hooks'

export const AppLayout = () => {
  useAuthenticatedRedirects()
  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset className="flex-col overflow-hidden rounded-md">
        <AppHeader />
        <ScrollArea>
          <div className="mx-auto max-w-(--breakpoint-xl) p-6 max-md:pb-22">
            <Outlet />
          </div>
        </ScrollArea>
        <AppFooter />
      </SidebarInset>
    </SidebarProvider>
  )
}
