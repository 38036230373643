import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader } from 'Components'
import { route } from 'Utils'

const invoices = [
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' },
  { id: 1, companyName: 'Text company', amount: 100.32, date: 'ieri' }
]

export function RecentInvoices() {
  return (
    <Section title="Waiting for payment" to={route('invoices')}>
      {invoices?.map(({ id, amount, avatarUrl, companyName, date }, i) => (
        <SectionItem key={i} to={`/invoices/${id}`}>
          <SectionItemHeader avatarUrl={avatarUrl} alt={companyName} />
          <SectionItemContent title={companyName} description={date} />
          <SectionItemFooter price={amount} />
        </SectionItem>
      ))}
    </Section>
  )
}
