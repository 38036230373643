import { buttonVariants, Section, SectionDetails } from 'Components'
import { Link } from 'react-router-dom'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { route } from 'Utils'

export const Client = ({ id: clientId = 234, name = 'client Name' }) => {
  return (
    <div>
      <h1 className="text-2xl font-semibold sm:tracking-tight">{name}</h1>

      <Link to={route('invoices.create', { clientId })} className={buttonVariants()}>
        <FiPlus />
        Invoice
      </Link>

      <Section>
        <SectionDetails
          items={[
            { title: 'CIF', value: 'das' },
            { title: 'City', value: '432 RON' },
            { title: 'Creation date', value: '432 RON' }
          ]}
        />
      </Section>

      <Section>
        <Link
          to={route('client.delete', { clientId })}
          className="hover:bg-secondary-background text-destructive flex items-center gap-x-4 rounded-xl px-3 py-4"
        >
          <FiTrash className="size-5" />
          <span className="grow">Delete client</span>
        </Link>
      </Section>
    </div>
  )
}
