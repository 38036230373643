import { useEffect } from 'react'
import { RegisterCompanyForm, AcceptCompanyForm } from '../auth/RegisterCompanyForm'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MainModal } from 'Components'

export const RegisterCompanyPanel = () => {
  const navigate = useNavigate()
  return (
    <MainModal title="Date firma pentru care se va crea contul">
      <RegisterCompanyForm onCloseForm={() => navigate('/')} />
    </MainModal>
  )
}

export const AcceptCompanyPanel = () => {
  const navigate = useNavigate()
  const invitation = useSelector((state) => state.auth.account.invitation)
  useEffect(() => {
    if (!invitation) navigate('/')
  }, [invitation, navigate])

  return (
    <MainModal>
      <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
        <AcceptCompanyForm
          onCloseForm={async () => {
            navigate('/')
          }}
        />
      </div>
    </MainModal>
  )
}
