import { FiPlus, FiSearch } from 'react-icons/fi'
import {
  Button,
  Input,
  NewClient,
  Section,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader
} from 'Components'
import { useState } from 'react'
import { route } from 'Utils'

const people = [
  { name: 'Leslie Alexander', lastActivity: 'Paid your invoice yesterday' },
  { name: 'Michael Foster' },
  { name: 'Dries Vincent', lastActivity: 'You sent an invoice 3h ago' },
  { name: 'Lindsay Walton', lastActivity: 'Paid your invoice 3h ago' },
  { name: 'Courtney Henry', lastActivity: 'You sent an invoice 3h ago' },
  { name: 'Tom Cook', lastActivity: 'Paid your invoice 3 days ago' }
]

function groupAndSortByName(people) {
  const groups = {}

  for (const person of people) {
    const firstLetter = person.name[0].toUpperCase()

    if (!groups[firstLetter]) {
      groups[firstLetter] = []
    }
    groups[firstLetter].push(person)
  }

  return Object.entries(groups).sort()
}

export const Clients = () => {
  const [searchItem, setSearchItem] = useState('')
  const [open, setOpen] = useState('')

  const handleInputChange = (e) => {
    setSearchItem(e.target.value)
  }

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <h1 className="text-2xl font-semibold sm:tracking-tight">Clienti</h1>

        <Button icon={FiPlus} onClick={() => setOpen(true)}>
          New
        </Button>
      </div>

      <div className="bg-background sticky top-0 z-40 space-y-3 pt-1 pb-2">
        <div className="relative">
          <FiSearch className="text-muted-foreground absolute top-1/2 left-2 size-4 -translate-y-1/2" />
          <Input type="search" value={searchItem} onChange={handleInputChange} placeholder="Name, CIF" className="pl-8" />
        </div>
        <div className="flex items-center">
          
        </div>
      </div>

      {searchItem ? <SearchedClients people={people} searchItem={searchItem} /> : <Contacts people={people} />}

      <NewClient open={open} setOpen={setOpen} />
    </>
  )
}

const SearchedClients = ({ people, searchItem }) => {
  const items = people.filter(({ name }) => name.toLowerCase().includes(searchItem.toLowerCase()))
  return (
    <Section title={`${items.length} items found`}>
      {items.map(({ imageUrl, name, lastActivity }, i) => (
        <SectionItem key={i} to={route('clients.client', { clientId: 32 })}>
          <SectionItemHeader avatarUrl={imageUrl} alt={name} />
          <SectionItemContent title={name} description={lastActivity} />
          <SectionItemFooter />
        </SectionItem>
      ))}
    </Section>
  )
}

const Contacts = ({ people }) => {
  const letters = groupAndSortByName(people)
  return letters.map(([letter, items]) => (
    <Section title={letter}>
      {items.map(({ imageUrl, name, lastActivity }, i) => (
        <SectionItem key={i} to={`/clients/372`}>
          <SectionItemHeader avatarUrl={imageUrl} alt={name} />
          <SectionItemContent title={name} description={lastActivity} />
          <SectionItemFooter />
        </SectionItem>
      ))}
    </Section>
  ))
}
