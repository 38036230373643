import API from 'API'
import { Actions } from 'Store'

export const loadAppScripts = () => {}

// will setup the initial store
export const loadAppInitialData = async () => {}

// saves the user profile
export const registerCompany = async (companyData) => {
  Actions.setLoadingAuth(true)
  const authTokens = await API._registerCompany(companyData)
  Actions.onNewTokensArrived(authTokens)
}

export const selectCompany = async (companyUserId) => {
  Actions.setLoadingAuth(true)
  const authTokens = await API._selectCompany(companyUserId)
  Actions.onNewTokensArrived(authTokens)
}

export const getRomanianCompanyByCUI = async (cif) => {
  const result = await API._getRomanianCompanyByCUI(cif)
  return result
}

export const searchRomanianCompanies = async (text) => {
  const result = await API._searchRomanianCompanies(text)
  return result
}

export const inviteEmployee = async ({ roles, userName, email } = {}) => {
  const result = await API._inviteEmployee({ roles, userName, email })
  return result
}
