import React, { useEffect, Fragment } from 'react'
// import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import {
  Time,
  CreatedActivity,
  AccountLinkedActivity,
  UpdatedActivity,
  AccountLoginActivity,
  getActivityIcon,
  DeletedActivity,
  AccountPermissionsUpdatedActivity,
  AccountUnlinkedActivity,
  Avatar,
  AvatarFallback,
  AvatarImage
} from 'Components'

import API from 'API'
import { Actions } from 'Store'
import { useList, useListItem } from 'Hooks'
import { cn, getInitialsByName } from 'Utils'

const getActivityComponent = (type, data) => {
  switch (type) {
    case 'CREATED':
      return <CreatedActivity data={data} />
    case 'UPDATED':
      return <UpdatedActivity data={data} />
    case 'DELETED':
      return <DeletedActivity data={data} />
    case 'ACCOUNT_LOGIN':
      return <AccountLoginActivity data={data} />
    case 'ACCOUNT_LINKED':
      return <AccountLinkedActivity data={data} />
    case 'ACCOUNT_PERMISSIONS_UPDATED':
      return <AccountPermissionsUpdatedActivity data={data} />
    case 'ACCOUNT_UNLINKED':
      return <AccountUnlinkedActivity data={data} />
    default:
      return null
  }
}

const ActivityLogItem = ({ id }) => {
  const activityLog = useListItem('userActivityLogs', id)
  if (!activityLog) return null
  return (
    <li className="ms-6 mb-10">
      <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
        {getActivityIcon(activityLog.type)}
      </span>
      <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-xs dark:border-gray-600 dark:bg-gray-700">
        <div className="items-center justify-between sm:flex">
          <div className="flex-1">{getActivityComponent(activityLog.type, activityLog.data)}</div>
          <div className="flex items-center gap-2 sm:ms-4">
            {activityLog.caller && (
              <div className="flex items-center gap-2">
                <Avatar className="h-6 w-6">
                  <AvatarImage src={activityLog.caller.avatarUrl} alt={activityLog.caller.name} />
                  <AvatarFallback className="text-xs">{getInitialsByName(activityLog.caller.name)}</AvatarFallback>
                </Avatar>
                <span className="text-sm text-gray-600 dark:text-gray-300">{activityLog.caller.name}</span>
              </div>
            )}
            <time className="text-xs font-normal text-gray-400 sm:order-last sm:mb-0">
              {<Time d={activityLog.ts} format="DD/MM/YYYY HH:mm" />}
            </time>
          </div>
        </div>
      </div>
    </li>
  )
}

export const UserActivityLogsContainer = () => {
  const logs = useList('userActivityLogs', 'ts', true)
  console.log('🚀 ~ UserActivityLogs ~ logs:', logs)
  useEffect(() => {
    const syncList = async () => {
      const userActivityLogs = await API._getUserActivityLogs()
      if (userActivityLogs?.error) return
      Actions.populateUserActivityLogs(userActivityLogs)
    }
    syncList()
  }, [])

  return (
    <ol className="relative border-s border-gray-200 dark:border-gray-700">
      {logs.map((log, i) => (
        <ActivityLogItem id={log.id} key={i} />
      ))}
    </ol>
  )
}

const activity = [
  {
    id: 1,
    type: 'comment',
    person: { name: 'Eduardo Benz', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ',
    date: '6d ago'
  },
  {
    id: 2,
    type: 'assignment',
    person: { name: 'Hilary Mahy', href: '#' },
    assigned: { name: 'Kristin Watson', href: '#' },
    date: '2d ago'
  },
  {
    id: 3,
    type: 'tags',
    person: { name: 'Hilary Mahy', href: '#' },
    tags: [
      { name: 'Bug', href: '#', color: 'fill-red-500' },
      { name: 'Accessibility', href: '#', color: 'fill-indigo-500' }
    ],
    date: '6h ago'
  },
  {
    id: 4,
    type: 'comment',
    person: { name: 'Jason Meyers', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.',
    date: '2h ago'
  }
]

export const UserActivityLogs = () => {
  return (
    <div className="flow-root">
      <div>
        <UserActivityLogsContainer />
      </div>
      <ul className="-mb-8">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id}>
            <div className="relative pb-8">
              {activityItemIdx !== activity.length - 1 ? (
                <span aria-hidden="true" className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" />
              ) : null}
              <div className="relative flex items-start space-x-3">
                {activityItem.type === 'comment' ? (
                  <>
                    <div className="relative">
                      <img
                        alt=""
                        src={activityItem.imageUrl}
                        className="flex size-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                      />

                      <span className="absolute -right-1 -bottom-0.5 rounded-tl bg-white px-0.5 py-px">
                        {/* <ChatBubbleLeftEllipsisIcon aria-hidden="true" className="size-5 text-gray-400" /> */}
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <a href={activityItem.person.href} className="font-medium text-gray-900">
                            {activityItem.person.name}
                          </a>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">Commented {activityItem.date}</p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <p>{activityItem.comment}</p>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'assignment' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="flex size-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                          {/* <UserCircleIcon aria-hidden="true" className="size-5 text-gray-500" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1.5">
                      <div className="text-sm text-gray-500">
                        <a href={activityItem.person.href} className="font-medium text-gray-900">
                          {activityItem.person.name}
                        </a>{' '}
                        assigned{' '}
                        <a href={activityItem.assigned.href} className="font-medium text-gray-900">
                          {activityItem.assigned.name}
                        </a>{' '}
                        <span className="whitespace-nowrap">{activityItem.date}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'tags' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="flex size-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                          {/* <TagIcon aria-hidden="true" className="size-5 text-gray-500" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-0">
                      <div className="text-sm/8 text-gray-500">
                        <span className="mr-0.5">
                          <a href={activityItem.person.href} className="font-medium text-gray-900">
                            {activityItem.person.name}
                          </a>{' '}
                          added tags
                        </span>{' '}
                        <span className="mr-0.5">
                          {activityItem.tags.map((tag) => (
                            <Fragment key={tag.name}>
                              <a
                                href={tag.href}
                                className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-gray-200 ring-inset"
                              >
                                <svg viewBox="0 0 6 6" aria-hidden="true" className={cn(tag.color, 'size-1.5')}>
                                  <circle r={3} cx={3} cy={3} />
                                </svg>
                                {tag.name}
                              </a>{' '}
                            </Fragment>
                          ))}
                        </span>
                        <span className="whitespace-nowrap">{activityItem.date}</span>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
