import {
  Button,
  NewProductModal,
  Section,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader
} from 'Components'
import { useState } from 'react'
import { FiPlus, FiServer } from 'react-icons/fi'
import { route } from 'Utils'

const products = [
  {
    name: 'dsadsad',
    description: 'description',
    icon: FiServer
  }
]

export const Products = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <h1 className="text-2xl font-semibold sm:tracking-tight">Products</h1>

        <Button
          icon={FiPlus}
          onClick={() => {
            setOpen(true)
          }}
        >
          New
        </Button>
      </div>

      <Section title={`${products.length} items found`}>
        {products.map(({ icon, name, description }, i) => (
          <SectionItem key={i} to={route('products', {})}>
            <SectionItemHeader icon={icon} hasBackground />
            <SectionItemContent title={name} description={description} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>

      <NewProductModal open={open} setOpen={setOpen} />
    </>
  )
}
