import { Section, SectionItem, SectionItemContent, SectionItemFooter, T } from 'Components'
import { SUPPORTED_LANGUAGES } from 'Constants'
import { FiCheck } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'

export const LanguageSelector = () => {
  const currentLanguage = useSelector((state) => state.locales.language)

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">{T('settings.language.title')}</h3>

      <Section>
        {SUPPORTED_LANGUAGES.map(({ name, code, locale }, i) => {
          const isActive = currentLanguage === code
          return (
            <SectionItem key={i} onClick={() => Actions.setLocale(locale)}>
              <SectionItemContent title={name} />
              {isActive && <SectionItemFooter icon={FiCheck} />}
            </SectionItem>
          )
        })}
      </Section>
    </>
  )
}
