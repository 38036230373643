import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader } from 'Components'
import { useSelector } from 'react-redux'
import { FiMail, FiUser } from 'react-icons/fi'

export const ProfileSettings = () => {
  const user = useSelector((state) => state.auth.account?.user)

  const items = [
    {
      label: 'Account',
      value: user.userName,
      icon: FiUser,
      to: '/settings/account/profile/details'
    },
    {
      label: 'Email',
      value: user.email,
      icon: FiMail,
      to: '/settings/account/profile/email'
    }
  ]

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Personal details</h3>

      <Section>
        {items.map(({ icon, to, label, value }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} hasBackground />
            <SectionItemContent title={label} description={value} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
