import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  DatePicker,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  InvoiceClientModal,
  MainModal,
  Section,
  SectionDetails,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader,
  Separator
} from 'Components'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiEdit2, FiPlus, FiServer } from 'react-icons/fi'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { route } from 'Utils'
import { z } from 'zod'

const items = [{ name: 'dsadsad', icon: FiServer, quantity: 3, price: 50 }]

export const NewInvoice = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const clientId = searchParams.get('clientId')

  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [clientModalOpen, setClientModalOpen] = useState(!clientId)

  const saveInvoice = () => {
    navigate(route('invoices.invoice', { invoiceId: 2 }))
  }

  return (
    <>
      <div className="grid gap-x-10 lg:grid-cols-3">
        <div className="lg:col-span-2">
          <Section>
            <button onClick={() => setDetailsModalOpen(true)} className="w-full">
              cdsaiao
            </button>
          </Section>

          <Section title="Client">
            {clientId ? (
              <SectionItem onClick={() => setClientModalOpen(true)}>
                <SectionItemHeader avatarUrl={''} alt={'FACTCURIER SRL'} />
                <SectionItemContent title={'FACTCURIER SRL'} description={'dsada'} />
                <SectionItemFooter icon={FiEdit2} />
              </SectionItem>
            ) : (
              <SectionItem onClick={() => setClientModalOpen(true)}>
                <SectionItemHeader icon={FiPlus} />
                <SectionItemContent title={'Choose a client'} />
              </SectionItem>
            )}
          </Section>

          <Section title="Items">
            {items.map(({ icon, name, quantity, price }, i) => (
              <SectionItem key={i}>
                <SectionItemHeader icon={icon} hasBackground />
                <SectionItemContent description={quantity} title={name} />
                <SectionItemFooter price={price} />
              </SectionItem>
            ))}
            {/* <Separator /> */}
            <SectionItem>
              <SectionItemHeader icon={FiPlus} hasBackground />
              <SectionItemContent title={'Add item'} />
            </SectionItem>
          </Section>
        </div>

        <div>
          <Section>
            <SectionDetails
              rightAlign
              items={[
                { title: 'Total fara TVA', value: '1000 RON' },
                { title: 'TVA', value: '432 RON' }
              ]}
            />
            <Separator />
            <SectionDetails rightAlign items={[{ title: 'Total', value: '1432 RON' }]} />
          </Section>

          <div className="bg-background mt-2 py-3 max-lg:sticky max-lg:bottom-16">
            <Button onClick={saveInvoice} disabled={false} className="w-full">
              Continue
            </Button>
          </div>
        </div>
      </div>

      <InvoiceDetailsModal open={detailsModalOpen} setOpen={setDetailsModalOpen} />
      <InvoiceClientModal open={clientModalOpen} setOpen={setClientModalOpen} />
    </>
  )
}

const formSchema = z.object({ dob: z.date({ required_error: 'A date of birth is required.' }) })

const InvoiceDetailsModal = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false)

  // const defaultValues = {
  //   userName: 'user.userName'
  // }

  const form = useForm({
    resolver: zodResolver(formSchema)
    // defaultValues
  })

  function onSubmit(data) {
    setIsLoading(true)
    console.log('🚀 ~ onSubmit ~ data:', data)
  }

  return (
    <MainModal title="Invoice details" open={open} setOpen={setOpen}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5 md:grid md:grid-cols-2 md:gap-x-5">
          <FormField
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Date</FormLabel>
                <DatePicker field={field} />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Number</FormLabel>
                <Input field={field} />
                <FormMessage />
              </FormItem>
            )}
          />

          <Button isLoading={isLoading} type="submit" className="w-full md:col-span-full">
            Update details
          </Button>
        </form>
      </Form>
    </MainModal>
  )
}
