import React, { useEffect } from 'react'
import { ListCard, Time, Badge, Button } from 'Components'
import API from 'API'
import { Actions } from 'Store'
import { FiLogOut } from 'react-icons/fi'

const SessionCardItem = ({ item }) => (
  <div className="bg-background hover:bg-hover transition-colors border-b border-border last:border-b-0">
    <div className="flex flex-col sm:flex-row sm:items-center gap-2 py-2 px-3">
      {/* ID Section */}
      <div className="sm:w-44 min-w-[140px]">
        <div className="w-20 text-[10px] truncate text-foreground">{item.jwtId}</div>
        <div className="text-xs text-secondary truncate">
          {item.userId && `usr: ${item.userId}`} {item.accountId && `acc: ${item.accountId}`}
        </div>
        <div className="text-xs text-secondary truncate">{item.email}</div>
      </div>

      {/* Badges Section */}
      <div className="grow min-w-[200px] hidden sm:block">
        <div className="flex flex-wrap items-center gap-1">
          {item.hasCompany && (
            <Badge variant="secondary" className="bg-primary/10 text-primary text-xs px-2 py-0.5">
              Firmǎ
            </Badge>
          )}
          {item.hasApp && (
            <Badge variant="secondary" className="bg-green-50 text-green-700 text-xs px-2 py-0.5">
              Aplicație
            </Badge>
          )}
          <Badge variant="secondary" className="bg-purple-50 text-purple-700 text-xs px-2 py-0.5">
            {item.accountType} {item.isAuthLink && 'Link'} {item.isEmailPin && 'PIN'}
          </Badge>
          {item.hasInvitation && (
            <Badge variant="secondary" className="bg-yellow-50 text-yellow-700 text-xs px-2 py-0.5">
              Invitație
            </Badge>
          )}
          {item.hasUser && (
            <Badge variant="secondary" className="bg-yellow-50 text-yellow-700 text-xs px-2 py-0.5">
              Utilizator
            </Badge>
          )}
        </div>
      </div>

      {/* Dates Section */}
      <div className="hidden sm:flex flex-col text-xs text-gray-500 min-w-[120px]">
        <div className="space-y-0.5">
          <div className="whitespace-nowrap text-gray-400">
            <Time d={item.createdAt} format="DD/MM/YYYY HH:mm" />
            {item.refreshedAt !== item.createdAt && (
              <>
                <span> • </span>
                <Time d={item.refreshedAt} format="DD/MM/YYYY HH:mm" />
              </>
            )}
          </div>
          <div className="whitespace-nowrap text-gray-400">
            <Time d={item.expiresAt} format="DD/MM/YYYY HH:mm" />
          </div>
        </div>
      </div>

      {/* Location Section */}
      <div className="min-w-[180px] hidden sm:block">
        <div className="text-sm text-foreground truncate">{item.location}</div>
        <div className="text-xs text-secondary truncate">{item.ipAddress}</div>
      </div>

      {/* Device Section */}
      <div className="min-w-[180px] hidden sm:block">
        <div className="text-sm text-foreground truncate">
          {item.browser} on {item.os}
        </div>
        <div className="text-xs text-secondary truncate">{item.deviceType}</div>
      </div>

      {/* Mobile View */}
      <div className="sm:hidden space-y-2">
        <div className="flex flex-wrap gap-1">
          {item.hasCompany && (
            <Badge variant="secondary" className="bg-blue-50 text-blue-700 text-xs">
              Company
            </Badge>
          )}
          {item.hasApp && (
            <Badge variant="secondary" className="bg-green-50 text-green-700 text-xs">
              App
            </Badge>
          )}
          {item.isAdmin && (
            <Badge variant="secondary" className="bg-purple-50 text-purple-700 text-xs">
              Admin
            </Badge>
          )}
          {item.isFullAccount && (
            <Badge variant="secondary" className="bg-yellow-50 text-yellow-700 text-xs">
              Full Account
            </Badge>
          )}
        </div>
        <div className="space-y-1">
          <p className="text-xs text-gray-600 truncate">
            {item.location} • {item.ipAddress}
          </p>
          <p className="text-xs text-gray-600 truncate">
            {item.browser} on {item.os} • {item.deviceType}
          </p>
          <div className="flex items-center gap-1 text-xs text-gray-500">
            <Time d={item.createdAt} format="DD/MM/YYYY HH:mm" />
            <span>•</span>
            <Time d={item.refreshedAt} format="DD/MM/YYYY HH:mm" />
            <span>•</span>
            <Time d={item.expiresAt} format="DD/MM/YYYY HH:mm" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const AdminSessions = () => {
  useEffect(() => {
    const syncList = async () => {
      const sessions = await API._getAdminSessions()
      if (sessions?.error) return
      Actions.populateAdminSessions(sessions)
    }
    syncList()
  }, [])

  const Action = ({ jwtId }) => (
    <Button variant="ghost" size="sm" onClick={() => Actions.logout(null, { jwtId })} className="text-red-600 hover:text-red-700">
      <FiLogOut className="h-4 w-4 mr-2" />
      Întrerupe sesiunea
    </Button>
  )

  return (
    <div className="flex h-svh max-h-svh flex-col overflow-auto p-4 bg-background">
      <h2 className="text-xl font-bold px-3 text-foreground">Toate sesiunile</h2>
      <div className="bg-secondary-background border-border rounded-lg shadow-xs mt-4">
        <ListCard id="jwtId" list="adminSessions" ItemComponent={SessionCardItem} action={Action} isDesc sortKey="createdAt" />
      </div>
    </div>
  )
}
