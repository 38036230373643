import { useSelector } from 'react-redux'
import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader } from 'Components'
import { route } from 'Utils'
import { FiPlus } from 'react-icons/fi'

export const AppCompanies = () => {
  const companies = useSelector((state) => state.auth.account?.userCompanies)

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Firmele mele</h3>

      <Section>
        <SectionItem to={'/modals/company'}>
          <SectionItemHeader icon={FiPlus} />
          <SectionItemContent title={'Adaugă o altă firmă'} />
        </SectionItem>
      </Section>

      <Section>
        {companies?.map(({ companyUserId, companyName, county, city }, i) => (
          <SectionItem key={i} to={route('account.companies.company', { companyId: companyUserId })}>
            <SectionItemContent title={companyName} description={`${city}, ${county}`} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
