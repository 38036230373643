import {
  AppHome,
  AppLayout,
  LoginPanel,
  RegisterCompanyPanel,
  NotAuthLayout,
  TailwindIndicator,
  SentInvoices,
  CompanySettings,
  ReceivedInvoices,
  AppSpv,
  NirInvoices,
  UserProfile,
  CompanyEmployees,
  Logout,
  AppSessions,
  AcceptCompanyPanel,
  WhoAmI,
  UserActivityLogs,
  EntityActivityLogs,
  Notifications,
  Settings,
  MobileSettings,
  SessionDevice,
  Clients,
  Client,
  NotificationSettings,
  Invoice,
  Apps,
  AccountSettings,
  AppCompanies,
  AppearanceForm,
  DeleteAccount,
  CompanyDetails,
  Share,
  EmployeeDetails,
  NewInvoice,
  UserEmail,
  ProfileSettings,
  PreferencesSettings,
  LanguageSelector,
  LoginProviders,
  Billing,
  PushNotifications,
  EmailNotifications,
  Whatsapp,
  TimezoneSelector,
  Products,
  
} from 'Components'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

export const AppRouter = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route path="whoami" element={<WhoAmI />} />
        <Route path="logout" element={<Logout />} />
        <Route element={<NotAuthLayout />}>
          <Route path="login" element={<LoginPanel hasGoogleLogin hasAppleLogin />} />
          <Route path="register" element={<LoginPanel hasGoogleLogin hasAppleLogin />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route index element={<AppHome />} />
          <Route path="expenses" element={<ReceivedInvoices />} />
          <Route path="products" element={<Products />} />
          <Route path="invoices">
            <Route index element={<SentInvoices />} />
            <Route path="new" element={<NewInvoice />} />
            <Route path=":invoiceId" element={<Invoice />} />
          </Route>
          <Route path="nir" element={<NirInvoices />} />
          <Route path="clients">
            <Route index element={<Clients />} />
            <Route path=":clientId" element={<Client />} />
          </Route>
          <Route path="notifications" element={<Notifications />} />
          <Route path="settings" element={<Settings />}>
            <Route index element={<MobileSettings />} />
            <Route path="notifications">
              <Route index element={<NotificationSettings />} />
              <Route path="push" element={<PushNotifications />} />
              <Route path="email" element={<EmailNotifications />} />
            </Route>
            <Route path="preferences">
              <Route index element={<PreferencesSettings />} />
              <Route path="theme" element={<AppearanceForm />} />
              <Route path="language" element={<LanguageSelector />} />
              <Route path="timezone" element={<TimezoneSelector />} />
            </Route>
            <Route path="account">
              <Route index element={<AccountSettings />} />
              <Route path="profile">
                <Route index element={<ProfileSettings />} />
                <Route path="details" element={<UserProfile />} />
                <Route path="email" element={<UserEmail />} />
              </Route>
              <Route path="companies">
                <Route index element={<AppCompanies />} />
                <Route path=":companyId" element={<CompanyDetails />} />
              </Route>
              <Route path="delete" element={<DeleteAccount />} />
              <Route path="logins" element={<LoginProviders />} />
              <Route path="sessions">
                <Route index element={<AppSessions />} />
                <Route path=":sessionId" element={<SessionDevice />} />
              </Route>
              <Route path="activity" element={<UserActivityLogs />} />
            </Route>
            <Route path="company" element={<CompanySettings />} />
            <Route path="spv" element={<AppSpv />} />
            <Route path="team">
              <Route index element={<CompanyEmployees />} />
              <Route path=":userId" element={<EmployeeDetails />} />
            </Route>
            <Route path="billing" element={<Billing />} />
            <Route path="logs" element={<EntityActivityLogs />} />
            <Route path="apps" element={<Apps />} />
          </Route>
          <Route path="apps">
            <Route path="whatsapp" element={<Whatsapp />} />
          </Route>
          <Route path="modals">
            <Route path="share" element={<Share />} />
            <Route path="company" element={<RegisterCompanyPanel />} />
            <Route path="invite" element={<AcceptCompanyPanel />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
    <TailwindIndicator />
  </>
)
