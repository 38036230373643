import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import {
  AppleAuthButton,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  GoogleAuthButton,
  Input,
  LinkedInAuthButton
} from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { getQueryParamsKey } from 'Utils'

export const validationSchema = z.object({
  email: z.string().min(1, 'Adresa de e-mail este necesară').email('Adresă de e-mail invalidă'),
  serverError: z.string().optional()
})

// the executeRecaptcha is passed from the parent component
export const LoginWithEmailForm = ({ executeRecaptcha, hasLinkedinLogin, hasGoogleLogin, hasAppleLogin }) => {
  const enabledProviders = hasLinkedinLogin || hasGoogleLogin || hasAppleLogin
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)
  const defaultValues = useMemo(
    () => ({
      email: getQueryParamsKey('email') || '',
      serverError: errorAuth?.message
    }),
    [errorAuth]
  )

  const form = useForm({ resolver: zodResolver(validationSchema), defaultValues })

  const { setFocus } = form

  const onSubmit = useCallback(
    async ({ email }) => {
      const captchaToken = await executeRecaptcha?.('login')
      if (email) {
        const isSent = await Actions.sendEmailAuthCode({ email, captchaToken })
        if (!isSent || isSent?.error)
          Actions.setErrorAuth(isSent?.error || { code: 'SERVER_ERROR', message: 'Server error, please retry later' })
        else {
          Actions.mergeAccount({ requestedEmail: email })
          Actions.setLoadingAuth(false)
        }
      }
    },
    [executeRecaptcha]
  )

  const buttonText = 'Trimite codul'

  useEffect(() => {
    setFocus('email')
  }, [setFocus])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Autentificare utilizator</h1>
      </div>
      <div className={`flex ${enabledProviders > 0 ? 'justify-center' : ''} gap-2`}>
        {hasLinkedinLogin && <LinkedInAuthButton disabled={isLoadingAuth} />}
        {hasGoogleLogin && <GoogleAuthButton disabled={isLoadingAuth} />}
        {hasAppleLogin && <AppleAuthButton disabled={isLoadingAuth} />}
      </div>

      {/* OR separator */}
      {(hasLinkedinLogin || hasGoogleLogin || hasAppleLogin) && (
        <div className="flex items-center justify-center">
          <div className="grow border-t border-gray-300"></div>
          <span className="px-4 text-sm text-gray-500">SAU</span>
          <div className="grow border-t border-gray-300"></div>
        </div>
      )}

      {/* Introductory text */}
      <p className="text-muted-foreground text-sm lg:w-2/3">
        Pentru a continua, te rugăm să introduci adresa ta de email. Îți vom trimite pe email un cod de acces în câteva secunde.
      </p>

      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="mb-2 block w-full text-sm text-gray-700">
              <FormLabel>Emailul tău pentru autentificare</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="email"
                  className="focus:shadow-outline mb-3 w-full rounded-xl border border-gray-300 px-3 py-2 leading-tight focus:outline-hidden"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {errorAuth && <p className="mt-3 text-sm text-red-500">{errorAuth?.message}</p>}
        <div className="mt-4 flex items-center justify-between">
          <Button type="submit" isLoading={isLoadingAuth} className="mr-2 flex-1">
            {buttonText}
          </Button>
        </div>
      </form>
      <p className="text-muted-foreground text-sm">
        Pentru a accesa aplicația Factcurier, este necesară autentificarea prealabilă.
      </p>
    </Form>
  )
}
