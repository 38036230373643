import { useSelector } from 'react-redux'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MainModal,
  Section,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch
} from 'Components'
import { FiShare2 } from 'react-icons/fi'
import { Actions } from 'Store'
import { useNavigate } from 'react-router-dom'
import { route } from 'Utils'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

export const CompanyEmployees = () => {
  const [open, setOpen] = useState(false)

  const companyUsers = useSelector((state) => state.auth.account?.company?.users)
  const employees = companyUsers.filter((user) => !user.isInvitation)
  const invitations = companyUsers.filter((user) => user.isInvitation)
  const hasInvitations = !!invitations.length

  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Echipa</h3>

      <Section>
        <SectionItem onClick={() => setOpen(true)}>
          <SectionItemHeader icon={FiShare2} />
          <SectionItemContent title={'Invită colegi'} />
        </SectionItem>
      </Section>

      <Section title="Angajați actuali">
        {employees?.map(({ userId, user }, i) => (
          <SectionItem key={i} to={route('settings.company.team.employee', { userId })}>
            <SectionItemHeader avatarUrl={user?.avatarUrl} alt={user?.userName} />
            <SectionItemContent title={user?.userName} description={'Last active: '} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>

      {hasInvitations && (
        <Section title="Pending Invitations">
          {invitations?.map(({ userId, user }, i) => (
            <SectionItem key={i} to={route('settings.company.team.employee', { userId })}>
              <SectionItemHeader avatarUrl={user?.avatarUrl} alt={user?.userName} />
              <SectionItemContent title={user?.userName} description={'Invited on'} />
              <SectionItemFooter />
            </SectionItem>
          ))}
        </Section>
      )}

      <InviteEmployeeModal open={open} setOpen={setOpen} />
    </>
  )
}

const formSchema = z.object({ name: z.string() })

const InviteEmployeeModal = ({ open, setOpen }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)
    const url = await Actions.inviteEmployee({
      roles: ['ADMIN'],
      userName: 'Some Name'
    })
    navigate('/modals/share', { state: { url } })
  }

  const form = useForm({ resolver: zodResolver(formSchema) })
  const isAdmin = form.watch('admin', false)

  return (
    <MainModal title={'Invită colegi'} open={open} setOpen={setOpen}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* <div className="flex items-center gap-2">
            <Switch id="admin-role" checked={isAdmin} onCheckedChange={setIsAdmin} />
            <Label htmlFor="admin-role">Admin</Label>
            {console.log(form)}
          </div> */}

          <FormField
            control={form.control}
            name="admin"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Full Access</FormLabel>
                <FormDescription>Receive emails about new products, features, and more.</FormDescription>
                <FormControl>
                  <Switch checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
              </FormItem>
            )}
          />

          {!isAdmin && (
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a verified email to display" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="m@example.com">m@example.com</SelectItem>
                      <SelectItem value="m@google.com">m@google.com</SelectItem>
                      <SelectItem value="m@support.com">m@support.com</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <Button isLoading={isLoading} type="submit" className="w-full">
            Share
          </Button>
        </form>
      </Form>
    </MainModal>
  )
}
