import { Avatar, AvatarFallback, AvatarImage, ResponsiveDropdown } from 'Components'
import { cn, getInitialsByName } from 'Utils'
import { Actions } from 'Store'
import { useSelector } from 'react-redux'
import { FiEdit2, FiPlus } from 'react-icons/fi'
import { useRef } from 'react'

const handleFileChange = async (e) => {
  const file = e.target.files?.[0]
  const contentType = file.type
  if (file && contentType.startsWith('image/')) {
    const reader = new FileReader()
    reader.onload = async (event) => {
      Actions.saveUserProfile({ avatarFile: { base64String: event.target?.result, contentType } })
    }
    reader.readAsDataURL(file)
  }
}

export const ProfileHeader = () => {
  const user = useSelector((state) => state.auth.account.user)
  // const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef(null)

  const setInputRef = (input) => {
    inputRef.current = input
  }

  return (
    <div className="mt-5 flex flex-col items-center">
      {user?.avatarUrl ? (
        <ResponsiveDropdown
          trigger={<Trigger setInputRef={setInputRef} user={user} />}
          items={[
            { label: 'Change image', onClick: () => inputRef.current?.click() },
            { label: 'Remove image', onClick: () => Actions.saveUserProfile({ avatarFile: null }) }
          ]}
        />
      ) : (
        <Trigger user={user} />
      )}
    </div>
  )
}

const Trigger = ({ user, setInputRef }) => {
  const Icon = user?.avatarUrl ? FiEdit2 : FiPlus
  return (
    <div className="relative cursor-pointer">
      <Avatar className="mx-auto size-16">
        <AvatarImage src={user?.avatarUrl} alt={user?.userName} />
        <AvatarFallback>{getInitialsByName(user?.userName)}</AvatarFallback>
      </Avatar>

      <div className="bg-primary absolute right-0 bottom-0 rounded-full p-0.5 text-white">
        <Icon size={14} />
      </div>

      <label for="file-upload" className={cn('absolute inset-0 cursor-pointer', setInputRef && 'hidden')}></label>
      <input id="file-upload" ref={setInputRef} type="file" className="hidden" accept="image/*" onChange={handleFileChange} />
    </div>
  )
}
