import { useSelector } from 'react-redux'

export const ApproveAppPanel = () => {
  const app = useSelector((state) => state.auth.account.app)
  const { appId, appName } = app || {}
  const invitation = useSelector((state) => state.auth.account.invitation)
  const { userName, email } = invitation || {}

  const handleApprove = async () => {
    // TODO: Implement app approval logic here
    // After approval, you might want to navigate somewhere
    // navigate('/admin/apps')
  }

  return (
    <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
      <div className="rounded-lg bg-white p-6 shadow-xs">
        <h2 className="mb-6 text-xl font-semibold">Cerere de aprobare aplicație</h2>

        <div className="space-y-4">
          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-600">#{appId}</span>
            <span className="text-lg font-bold text-primary">{appName}</span>
          </div>

          <div className="space-y-2 mt-4">
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-gray-600">Requested by:</span>
              <span className="text-sm">{userName}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-gray-600">Email:</span>
              <span className="text-sm text-gray-500">{email}</span>
            </div>
          </div>
        </div>

        <button
          onClick={handleApprove}
          className="mt-6 w-full rounded-md bg-primary px-4 py-2 text-white hover:bg-primary/90 focus:outline-hidden focus:ring-2 focus:ring-primary focus:ring-offset-2 transition-colors"
        >
          Approve App
        </button>
      </div>
    </div>
  )
}
