import { Input, Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader } from 'Components'
import { APP_SETTINGS_ITEMS } from 'Constants'
import { useIsMobile } from 'Hooks'
import { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { route } from 'Utils'

export const MobileSettings = () => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [searchTerm, setSearchItem] = useState('')
  if (!isMobile) navigate(route('settings.company'))

  const handleInputChange = (e) => {
    setSearchItem(e.target.value)
  }

  return (
    <div className="space-y-5">
      <h3 className="text-2xl font-semibold sm:tracking-tight">Settings</h3>

      <div className="relative">
        <FiSearch className="text-muted-foreground absolute top-1/2 left-2 size-4 -translate-y-1/2" />
        <Input type="search" value={searchTerm} onChange={handleInputChange} placeholder="Search" className="pl-8" />
      </div>

      {searchTerm ? <SearchedSettings searchTerm={searchTerm} /> : <BaseSettings />}
    </div>
  )
}

const BaseSettings = () => {
  return APP_SETTINGS_ITEMS.map(({ items, mobileOnly }, index) => (
    <Section key={index}>
      {items.map(({ icon, to, name }, i) => (
        <SectionItem key={i} to={route(to)}>
          <SectionItemHeader icon={icon} />
          <SectionItemContent title={name} />
          {!mobileOnly && <SectionItemFooter />}
        </SectionItem>
      ))}
    </Section>
  ))
}

function searchItems(searchTerm, data) {
  const results = []
  searchTerm = searchTerm.toLowerCase()

  for (const { items } of data) {
    for (const item of items || []) {
      if (item.name.toLowerCase().includes(searchTerm)) {
        results.push(item)
      }
      if (item.items) {
        results.push(...searchItems(searchTerm, [item]))
      }
    }
  }

  return results
}

const SearchedSettings = ({ searchTerm }) => {
  const items = searchItems(searchTerm, APP_SETTINGS_ITEMS)
  return (
    <Section>
      {items.map(({ icon, to, name }, i) => (
        <SectionItem key={i} to={route(to)}>
          <SectionItemHeader icon={icon} />
          <SectionItemContent title={name} />
          <SectionItemFooter />
        </SectionItem>
      ))}
    </Section>
  )
}
