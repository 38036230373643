import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  AppApprovalNotification,
  NewAccountNotification,
  buttonVariants
} from 'Components'
import { FiBell, FiSettings } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { cn, route } from 'Utils'
import { useIsMobile } from 'Hooks'

export const NotificationsMenu = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  let notifications = useSelector((state) => state.auth.account.userNotifications)
  notifications = (notifications || [])
    .map((notification) => {
      let component
      if (notification.type === 'APP_APPROVAL') component = AppApprovalNotification
      if (notification.type === 'NEW_ACCOUNT') component = NewAccountNotification
      return {
        ...notification,
        component
      }
    })
    .filter((notification) => notification.component)
  // const unreadCount = notifications.filter((n) => !n.isRead).length
  const unreadCount = 3
  const [open, setOpen] = useState(false)

  if (isMobile) return <NotificationsButton unreadCount={unreadCount} />

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <NotificationsButton unreadCount={unreadCount} />
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-96 rounded-xl shadow-lg" sideOffset={8}>
        <div className="flex items-center justify-between border-b px-4 py-3">
          <h3 className="font-medium">Notificări</h3>
          <button
            onClick={() => {
              setOpen(false)
              navigate(route('account.notifications'))
            }}
            className="hover:bg-accent rounded-md p-1.5 transition-colors"
            title="Setări notificări"
          >
            <FiSettings size={18} className="text-muted-foreground" />
          </button>
        </div>

        {notifications.length === 0 ? (
          <div className="text-muted-foreground px-4 py-8 text-center text-sm">Nu aveți notificări</div>
        ) : (
          <div className="max-h-[400px] overflow-y-auto">
            {notifications.map((notification, index) => {
              const notificationData = { ...notification.data, id: notification.id, createdAt: notification.createdAt }
              return (
                <React.Fragment key={notification.id}>
                  <DropdownMenuItem
                    className={cn('hover:bg-accent focus:bg-accent px-4 py-2', !notification.isRead && 'bg-accent/40')}
                  >
                    <notification.component {...notificationData} />
                  </DropdownMenuItem>
                  {index < notifications.length - 1 && <DropdownMenuSeparator className="pointer-events-none my-0" />}
                </React.Fragment>
              )
            })}
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const NotificationsButton = ({ unreadCount }) => {
  return (
    <Link to={route('notifications')} className={buttonVariants({ size: 'icon', variant: 'ghost', className: 'relative' })}>
      <FiBell size={20} />
      {unreadCount > 0 && (
        <>
          <span className="bg-primary absolute top-0 right-0 size-3 animate-ping rounded-full opacity-75"></span>
          <span className="bg-primary absolute top-0 right-0 size-3 rounded-full"></span>
        </>
      )}
    </Link>
  )
}
