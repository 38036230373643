import { ProfileHeader, Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader } from 'Components'
import { FiBriefcase, FiKey, FiList, FiMonitor, FiTrash2, FiUser } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { route } from 'Utils'

const items = [
  {
    title: 'Personal details',
    icon: FiUser,
    to: route('/settings/account/profile')
  },
  {
    title: 'Companies',
    icon: FiBriefcase,
    to: route('account.companies')
  },
  {
    title: 'Sesiuni active',
    icon: FiMonitor,
    to: route('account.sessions')
  },
  {
    title: 'Sign-in options',
    icon: FiKey,
    to: route('/settings/account/logins')
  },
  {
    title: 'Activitate',
    icon: FiList,
    to: route('/settings/account/activity')
  }
]

export const AccountSettings = () => {
  const user = useSelector((state) => state.auth.account?.user)

  return (
    <>
      <ProfileHeader user={user} />

      <Section>
        {items.map(({ icon, to, title }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} />
            <SectionItemContent title={title} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>

      <Section>
        <SectionItem to={route('account.delete')} className={'text-destructive'}>
          <SectionItemHeader icon={FiTrash2} />
          <SectionItemContent title={'Dezactivează contul'} />
        </SectionItem>
      </Section>
    </>
  )
}
