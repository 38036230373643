import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  MainModal,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from 'Components'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiLink, FiMessageCircle, FiMoreHorizontal } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

const accountFormSchema = z.object({
  email: z.string().email()
})

export const Share = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { url } = location.state || {}

  const [copied, setCopied] = useState(false)

  const form = useForm({
    resolver: zodResolver(accountFormSchema)
  })

  const onSubmit = ({ email }) => {
    console.log('🚀 ~ Share ~ data:', email)
    navigate(-1)
  }

  const copy = (value) => {
    copyToClipboard(value)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <MainModal title="Adauga utilizator">
      <div className="space-y-6 divide-y">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {/* Name Section */}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="grow">
                  <FormControl>
                    <Input type="email" placeholder="Emailul noului utilizator" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="mt-4 w-full">
              Send
            </Button>
          </form>
        </Form>
        <div className="flex items-center gap-x-4 pt-4 md:pt-6">
          <TooltipProvider delayDuration={0}>
            <Tooltip open={copied}>
              <TooltipTrigger>
                <ShareButton type="Copy Link" icon={FiLink} action={() => copy(url)} />
              </TooltipTrigger>
              <TooltipContent>Copied!</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <ShareButton type="Whatsapp" icon={FiMessageCircle} action={() => whatsappShare(url)} />
          <ShareButton type="More" icon={FiMoreHorizontal} action={() => nativeShare(url)} />
        </div>
      </div>
    </MainModal>
  )
}

const ShareButton = ({ icon: Icon, type, action }) => {
  return (
    <div className="w-16 space-y-1">
      <Button variant="outline" onClick={() => action()} className="size-16 rounded-full">
        <Icon className="size-5!" />
      </Button>
      <p className="truncate text-center text-xs">{type}</p>
    </div>
  )
}

const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value)
}

const nativeShare = async (value) => {
  if (navigator?.share) {
    try {
      await navigator.share({
        title: 'Invitație acces Factcurier',
        text: 'Te invit să te alături companiei noastre',
        url: value
      })
    } catch {
      //
    }
  }
}

const whatsappShare = (value) => {
  window.open(`whatsapp://send?text=${encodeURIComponent('Te invit să te alături companiei noastre: ' + value)}`)
}
