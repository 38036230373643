import { useSelector } from 'react-redux'
import {
  AcceptAppForm,
  AcceptCompanyForm,
  AppCard,
  AppInvitationCard,
  Card,
  CompanyCard,
  CompanyInvitationCard,
  EmailSentForm,
  LoginWithEmailForm,
  RegisterAppForm,
  RegisterCompanyForm,
  UserProfileCard,
  UserProfileForm,
  WithRecaptcha
} from 'Components'
import { Actions } from 'Store'
import { FiLogOut } from 'react-icons/fi'
import { getProduct } from 'Utils'
import { Link, useLocation } from 'react-router-dom'

export const LoginPanel = ({ hasLinkedinLogin, hasGoogleLogin, hasAppleLogin }) => {
  const product = getProduct()
  const requestedEmail = useSelector((state) => state.auth.account.requestedEmail)
  const jwtId = useSelector((state) => state.auth.account.jwtId)
  const isResetVisible = jwtId || requestedEmail
  const user = useSelector((state) => state.auth.account.user)
  const { email, userName } = user || {}
  const invitation = useSelector((state) => state.auth.account.invitation)
  const company = useSelector((state) => state.auth.account.company)
  const app = useSelector((state) => state.auth.account.app)

  const isRegisterRoute = useLocation().pathname.startsWith('/register')

  const showLoginForm = !isRegisterRoute && !requestedEmail && !email
  const showEmailSent = !isRegisterRoute && requestedEmail && !email
  const showProfile = !isRegisterRoute && email && !userName

  const showAppRegister = !invitation && !app && product === 'DEVPLATFORM' && (isRegisterRoute || userName)
  const showCompanyRegister = !invitation && !company && product === 'WEBAPP' && (isRegisterRoute || userName)
  const showAppAccept = invitation?.app && product === 'DEVPLATFORM' && userName && email
  const showCompanyAccept = invitation?.company && product === 'WEBAPP' && userName && email
  const showSomeCard = invitation || app || company || user

  return (
    <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
      {isResetVisible && (
        <>
          <div className="absolute top-4 right-4">
            <button onClick={() => Actions.logout()} className="rounded-full p-2 hover:bg-gray-100" title="Logout">
              <FiLogOut className="h-6 w-6" />
            </button>
          </div>
          {showSomeCard && (
            <Card className="w-full max-w-md space-y-6 rounded-lg bg-white p-6 shadow-xl">
              {invitation?.app && !showAppAccept && <AppInvitationCard invitation={invitation} />}
              {invitation?.company && !showCompanyAccept && <CompanyInvitationCard invitation={invitation} />}
              {app && <AppCard app={app} />}
              {company && <CompanyCard company={company} />}
              {user && <UserProfileCard user={user} />}
            </Card>
          )}
        </>
      )}

      {showLoginForm && (
        <WithRecaptcha>
          <LoginWithEmailForm {...{ hasLinkedinLogin, hasGoogleLogin, hasAppleLogin }} />
        </WithRecaptcha>
      )}
      {showEmailSent && (
        <WithRecaptcha>
          <EmailSentForm />
        </WithRecaptcha>
      )}
      {showProfile && <UserProfileForm />}
      {showAppRegister && <RegisterAppForm />}
      {showAppAccept && <AcceptAppForm />}
      {showCompanyRegister && <RegisterCompanyForm />}
      {showCompanyAccept && <AcceptCompanyForm />}
      {isRegisterRoute && !isResetVisible && (
        <p className="text-muted-foreground text-center text-sm">
          Aveți deja un cont?{' '}
          <Link to="/login" className="hover:text-primary underline underline-offset-4">
            Autentificare
          </Link>
        </p>
      )}
      {/* if login route and no jwtId, show register link */}
      {!isRegisterRoute && !isResetVisible && (
        <p className="text-muted-foreground text-center text-sm">
          Nu aveți deja un cont?{' '}
          <Link to="/register" className="hover:text-primary underline underline-offset-4">
            Înregistrare
          </Link>
        </p>
      )}
    </div>
  )
}
