import * as React from 'react'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SidebarMenuButton
} from 'Components'
import { Link } from 'react-router-dom'
import { useIsMobile } from 'Hooks'
import { FiChevronDown, FiPlus } from 'react-icons/fi'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { cn } from 'Utils'
import { Actions } from 'Store'

export const CompanySelector = () => {
  const isMobile = useIsMobile()

  const [isComboOpen, setIsComboOpen] = useState(false)

  const activeCompanyName = useSelector((state) => state.auth.account?.company?.companyName)
  const activeCompanyUserId = useSelector((state) => state.auth.account?.company?.companyUserId)
  const companies = useSelector((state) => state.auth.account?.userCompanies)

  const props = { isComboOpen, setIsComboOpen, activeCompanyName, activeCompanyUserId, companies }

  if (isMobile) return <CompaniesMobile {...props} />
  return <CompaniesDesktop {...props} />
}

const CompaniesDesktop = ({ isComboOpen, setIsComboOpen, activeCompanyName, companies, activeCompanyUserId }) => {
  return (
    <>
      <Popover open={isComboOpen} onOpenChange={setIsComboOpen}>
        <PopoverTrigger asChild>
          <SidebarMenuButton
            size="lg"
            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
          >
            <div className="bg-sidebar-primary text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg">
              {/* <activeTeam.logo className="size-4" /> */}
            </div>
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate font-semibold">{activeCompanyName}</span>
              <span className="truncate text-xs">Basic</span>
            </div>
            <FiChevronDown className="ml-auto" />
          </SidebarMenuButton>
        </PopoverTrigger>
        <PopoverContent className="w-72 p-0" side="right" align="start">
          <Companies companies={companies} activeCompanyUserId={activeCompanyUserId} />
        </PopoverContent>
      </Popover>
    </>
  )
}

const CompaniesMobile = ({ isComboOpen, setIsComboOpen, activeCompanyName, companies, activeCompanyUserId }) => {
  // const isCompanyLoading = useSelector((state) => state.auth.isLoadingAuth)

  return (
    <Drawer open={isComboOpen} onOpenChange={setIsComboOpen}>
      <DrawerTrigger className="flex items-center">
        <span className="truncate font-semibold">{activeCompanyName}</span>
        <FiChevronDown className="ml-auto" />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Firme</DrawerTitle>
          <DrawerDescription>Firmele cu care lucrezi</DrawerDescription>
        </DrawerHeader>
        <Companies companies={companies} activeCompanyUserId={activeCompanyUserId} />
      </DrawerContent>
    </Drawer>
  )
}

const Companies = ({ companies, activeCompanyUserId }) => {
  return (
    <>
      <Command>
        <CommandInput placeholder="Search company..." />
        <CommandList>
          <CommandEmpty>Nu s-au gasit rezultate.</CommandEmpty>
          <CommandGroup>
            {companies?.map(({ companyName, companyUserId }, index) => (
              <CommandItem
                key={index}
                onSelect={() => {
                  Actions.selectCompany(companyUserId)
                }}
                className={cn(
                  'text-sm',
                  activeCompanyUserId === companyUserId && 'bg-primary/5 text-primary aria-selected:bg-primary/10'
                )}
              >
                <p className="truncate">{companyName}</p>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
        <CommandSeparator />
      </Command>
      <Link to="/modals/company" className="flex items-center p-2">
        <FiPlus className="h-5 w-5" />
        Adauga alta firma
      </Link>
    </>
  )
}
