import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from 'Components'
import { useIsMobile } from 'Hooks'
import { useState } from 'react'

export const ResponsiveDropdown = ({ trigger, items, title, description } = {}) => {
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()

  if (isMobile)
    return (
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger>{trigger}</DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>{title}</DrawerTitle>
            <DrawerDescription>{description}</DrawerDescription>
          </DrawerHeader>
          <div className="m-4">
            {items.map(({ label, onClick }, i) => (
              <button key={i} onClick={onClick}>
                {label}
              </button>
            ))}
          </div>
        </DrawerContent>
      </Drawer>
    )

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent side="right">
        {items.map(({ label, onClick }, i) => (
          <DropdownMenuItem key={i} onClick={onClick}>
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
