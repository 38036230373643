const routes = {
  home: '/',
  logout: '/logout',
  settings: '/settings',
  invoices: '/invoices',
  'invoices.invoice': '/invoices/{invoiceId}',
  'invoices.create': '/invoices/new',
  expenses: '/expenses',
  products: '/products',
  notifications: '/notifications',
  nir: '/nir',
  clients: '/clients',
  'clients.client': '/clients/{clientId}',
  'client.delete': '/clients/{clientId}/delete',
  'settings.company': '/settings/company',
  'settings.company.spv': '/settings/spv',
  'settings.company.billing': '/settings/billing',
  'settings.company.team': '/settings/team',
  'settings.company.team.employee': '/settings/team/{userId}',
  'settings.company.logs': '/settings/logs',
  'settings.company.apps': '/settings/apps',
  account: '/settings/account',
  'account.delete': '/settings/account/delete',
  'account.notifications': '/settings/notifications',
  'account.notifications.push': '/settings/notifications/push',
  'account.notifications.email': '/settings/notifications/email',
  'account.preferences': '/settings/preferences',
  'account.preferences.language': '/settings/preferences/language',
  'account.preferences.theme': '/settings/preferences/theme',
  'account.preferences.timezone': '/settings/preferences/timezone',
  'account.sessions': '/settings/account/sessions',
  'account.sessions.session': '/settings/account/sessions/{sessionId}',
  'account.companies': '/settings/account/companies',
  'account.companies.company': '/settings/account/companies/{companyId}',
  'apps.whatsapp': '/apps/whatsapp'
}

export const route = (name, params = {}) => {
  const path = routes[name]
  if (!path) return name

  // Replace placeholders in the path
  const replacedPath = path.replace(/\{([^}]+)\}/g, (_, key) => params[key] || '')

  // Create query string from remaining params (excluding placeholder keys)
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key]) => !path.includes(`{${key}}`)))
  const queryString = new URLSearchParams(queryParams).toString()

  return queryString ? `${replacedPath}?${queryString}` : replacedPath
}

export const routeIs = ({ name, pathname } = {}) => {
  return pathname.startsWith(route(name))
}

export const isInternalRoute = (name) => {
  return !!routes[name]
}
