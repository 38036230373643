import { DateTime, Section, SectionDetails, SectionItem, SectionItemContent, SectionItemHeader, Skeleton } from 'Components'
import { useListItem } from 'Hooks'
import { useState } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Actions } from 'Store'

export const SessionDevice = () => {
  let { sessionId } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const isActive = useSelector((state) => state.auth.account.jwtId === sessionId)
  const { deviceType, os, browser, ipAddress, createdAt, location, refreshedAt } = useListItem('userSessions', sessionId) || {}

  const logout = async () => {
    if (isActive) {
      await Actions.logout()
    } else {
      await Actions.logout(null, { jwtId: sessionId })
      navigate(-1)
    }
  }

  return (
    <div>
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{os}</h3>
        <p className="text-sm/6 text-gray-500">{isActive ? 'This Device' : <DateTime d={refreshedAt} />}</p>
      </div>

      <Section>
        <SectionDetails
          items={[
            { title: 'Browser', value: browser },
            { title: 'Device', value: deviceType },
            { title: 'Location', value: isLoading ? <Skeleton className="h-5 w-[150px]" /> : <DateTime d={createdAt} /> },
            { title: 'IP', value: isLoading ? <Skeleton className="h-5 w-[150px]" /> : ipAddress }
          ]}
        />
      </Section>

      <Section>
        <SectionItem onClick={() => logout()} className="text-destructive">
          <SectionItemHeader icon={FiLogOut} />
          <SectionItemContent title={'Întrerupe sesiunea'} />
        </SectionItem>
      </Section>
    </div>
  )
}
