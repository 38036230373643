import {
  EmailConfirmationSent,
  Input,
  MainModal,
  ScrollArea,
  Section,
  SectionItem,
  SectionItemContent,
  SectionItemFooter,
  SectionItemHeader
} from 'Components'
import { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useSearchParams } from 'react-router-dom'

const clients = [
  {
    name: 'Leslie Alexander',
    lastActivity: 'Paid your invoice yesterday'
  },
  {
    name: 'Michael Foster'
  },
  {
    name: 'Dries Vincent',
    lastActivity: 'You sent an invoice 3h ago'
  },
  {
    name: 'Lindsay Walton',
    lastActivity: 'Paid your invoice 3h ago'
  },
  {
    name: 'Courtney Henry',
    lastActivity: 'You sent an invoice 3h ago'
  },
  {
    name: 'Tom Cook',
    lastActivity: 'Paid your invoice 3 days ago'
  }
]

const companies = [
  {
    name: 'Michdasael Foster'
  },
  {
    name: 'Driedsas Vincent',
    lastActivity: 'You sent an invoice 3h ago'
  },
  {
    name: 'Linddsasay Walton',
    lastActivity: 'Paid your invoice 3h ago'
  },
  {
    name: 'Courtnasssey Henry',
    lastActivity: 'You sent an invoice 3h ago'
  },
  {
    name: 'Toddm Cook',
    lastActivity: 'Paid your invoice 3 days ago'
  }
]

export const InvoiceClientModal = ({ trigger, open, setOpen }) => {
  const [searchItem, setSearchItem] = useState('')
  const [, setSearchParams] = useSearchParams()

  const handleInputChange = (e) => {
    setSearchItem(e.target.value)
  }

  const handleClientChange = (clientId) => {
    setSearchParams({ clientId }, { replace: true })
    setOpen(false)
  }

  const items = clients.filter(({ name }) => name.toLowerCase().includes(searchItem.toLowerCase()))
  const companies2 = companies.filter(({ name }) => name.toLowerCase().includes(searchItem.toLowerCase()))

  return (
    <MainModal title="Choose a client" className="pb-0" trigger={trigger} open={open} setOpen={setOpen}>
      <div className="relative grow">
        <FiSearch className="text-muted-foreground absolute top-1/2 left-2 size-4 -translate-y-1/2" />
        <Input
          type="search"
          value={searchItem}
          onChange={handleInputChange}
          placeholder="Numele companiei, CIF"
          className="pl-8"
        />
      </div>

      {companies2.length || items.length ? (
        <ScrollArea className="h-96 md:h-72">
          <div className="pb-6">
            {!!items.length && (
              <Section title="Recent clients" className="sm:mt-0">
                {items.map(({ imageUrl, name, lastActivity }, i) => (
                  <SectionItem key={i} onClick={() => handleClientChange(i)}>
                    <SectionItemHeader avatarUrl={imageUrl} alt={name} />
                    <SectionItemContent title={name} description={lastActivity} />
                    <SectionItemFooter />
                  </SectionItem>
                ))}
              </Section>
            )}

            {searchItem && !!companies2.length && (
              <Section title="Other companies">
                {companies2.map(({ imageUrl, name, lastActivity }, i) => (
                  <SectionItem key={i} onClick={() => handleClientChange(i)}>
                    <SectionItemHeader avatarUrl={imageUrl} alt={name} />
                    <SectionItemContent title={name} description={lastActivity} />
                    <SectionItemFooter />
                  </SectionItem>
                ))}
              </Section>
            )}
          </div>
        </ScrollArea>
      ) : (
        <Section>
          <EmailConfirmationSent />
        </Section>
      )}
    </MainModal>
  )
}
