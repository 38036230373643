import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader } from 'Components'
import { FiBell, FiMail } from 'react-icons/fi'
import { route } from 'Utils'

const items = [
  {
    title: 'Push notifications',
    icon: FiBell,
    to: route('account.notifications.push')
  },
  {
    title: 'Email notifications',
    icon: FiMail,
    to: route('account.notifications.email')
  }
]

export const NotificationSettings = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Notifications</h3>

      <Section>
        {items.map(({ icon, to, title }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} />
            <SectionItemContent title={title} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
