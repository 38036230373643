import { Button, DateTime, Section, SectionDetails } from 'Components'
import { FiLogIn } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Actions } from 'Store'
import { route } from 'Utils'

export const CompanyDetails = () => {
  let { companyId } = useParams()
  const navigate = useNavigate()

  const companies = useSelector((state) => state.auth.account?.userCompanies)
  const companyUserId = useSelector((state) => state.auth.account?.company?.companyUserId)
  const isActive = companyUserId === companyId

  const { companyName, city, cif, county, createdAt } = companies.find((company) => company.companyUserId === companyId)

  const handleCompanyLogin = async () => {
    navigate(route('home'))
    await Actions.selectCompany(companyId)
  }

  return (
    <div className="space-y-5">
      <div>
        <h3 className="text-2xl font-semibold sm:tracking-tight">{companyName}</h3>
        {isActive ? (
          <p className="text-sm/6 text-gray-500">This company</p>
        ) : (
          <Button size="sm" icon={FiLogIn} onClick={handleCompanyLogin} className="mt-2 flex gap-x-1.5">
            Login
          </Button>
        )}
      </div>

      <Section>
        <SectionDetails
          items={[
            { title: 'CIF', value: cif },
            { title: 'City', value: `${city}, ${county}` },
            { title: 'Creation date', value: <DateTime d={createdAt} /> }
          ]}
        />
      </Section>
    </div>
  )
}
