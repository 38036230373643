import { Section, SectionItem, SectionItemContent, SectionItemFooter, SectionItemHeader } from 'Components'
import { FiClock, FiGlobe, FiSun } from 'react-icons/fi'
import { route } from 'Utils'

const items = [
  {
    title: 'Language',
    icon: FiGlobe,
    to: route('account.preferences.language')
  },
  {
    title: 'Theme',
    icon: FiSun,
    to: route('account.preferences.theme')
  },
  {
    title: 'Timezone',
    icon: FiClock,
    to: route('account.preferences.timezone')
  }
]

export const PreferencesSettings = () => {
  return (
    <>
      <h3 className="text-2xl font-semibold sm:tracking-tight">Preferences</h3>
      <Section>
        {items.map(({ icon, to, title }, i) => (
          <SectionItem key={i} to={to}>
            <SectionItemHeader icon={icon} />
            <SectionItemContent title={title} />
            <SectionItemFooter />
          </SectionItem>
        ))}
      </Section>
    </>
  )
}
